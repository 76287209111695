import React, { useEffect, useState } from "react";
import ScoreCard from "../ScoreCard/ScoreCard";

function WelcomeScoreCards({ page = "" }) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch(process.env.NEXT_PUBLIC_API_URL_API_URL + "/api/welcome/scorecards")
      .then((res) => res.json())
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          setGames([]);
        } else {
          const gamesList = Object.values(res.data);
          if (page == "marchmadness") {
            setGames(Array.isArray(gamesList[0]) ? gamesList[0] : [gamesList[0]]);
          } else {
            setGames(gamesList);
          }
        }
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <>
      {games && games?.length > 0 && (
        <>
          {page == "marchmadness" ? (
            <></>
          ) : (
            <>
              <h3 className="text-center">ScoreCenter for Live Games. There's Nothing Like It</h3>
              <p className="mt-3 mb-0 text-center" style={{ fontWeight: "bold" }}>
                Level the playing field with live game ShotQuality analysis in ScoreCenter for NCAA, NBA, and WNBA
                games. ScoreCenter unlocks expected performance metrics to power your live bets. Standard members have
                access to select games. Premium Members have access to all games.
              </p>
            </>
          )}

          <div className="mt-5 Scoreside">
            <div className="gameCardList">
              {Object.entries(games).map(([date, game], index) => (
                <ScoreCard
                  isLive={false}
                  key={game?.Game_ID}
                  index={0}
                  data={game}
                  isStandardPlan={false}
                  isPremiumPlan={false}
                  leagueFromUrl={"college_mens"}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default WelcomeScoreCards;
