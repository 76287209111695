import React from "react";

function StarCheckbox({ checked, handleCheck, title = "", ...rest }) {
  return (
    <input
      className="star-checkbox star"
      type="checkbox"
      title={title}
      checked={checked}
      onChange={handleCheck}
      {...rest}
    />
  );
}

export default StarCheckbox;
